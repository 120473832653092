export default {

	// api请求地址
	baseUrl: 'https://manage.etaomall.com',

	// 图片域名
	imgDomain: 'https://manage.etaomall.com',

	// 腾讯地图key
	 mpKey: 'VWLBZ-OZZKT-NGNXY-LWVJZ-MPXCJ-2RFN7',

	// 客服
	 webSocket: '',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}
